import { useContext, useEffect, useRef, useState } from "react";

import TypingTextV2 from "../../components/TypingTextV2";
import StyledContentPanel from "../../components/StyledContentPanel";
import { animated, useSpring } from "react-spring";
import { TopicV2 } from "@kazvabg/voterapp-model";
import StyledButton from "../../components/StyledButton";
import { AppContext } from "../../App";
import DisplayMedia from "./DisplayMedia";

const Component: React.FC<{ onComplete: () => void, topic: TopicV2 }> = (props) => {

    const { cookieConsent } = useContext(AppContext);
    const animationsStartedRef = useRef<boolean>(false);

    useEffect(() => {
        if (cookieConsent && !animationsStartedRef.current) {
            animationsStartedRef.current = true;
            startAnimations();
        }
    }, [cookieConsent]);

    const startAnimations = () => {
        setState({ ...state, currentMessageFadeIn: 0 });
    };

    const [state, setState] = useState({
        currentMessageFadeIn: -1,
        currentMessageText: -1
    });

    const [titlePanelStyle, titlePanelApi] = useSpring(() => ({
        opacity: 1,
        config: { duration: 600 },
    }));

    const greetingCount = props.topic.greetingMessage.messages.length;

    const messageFadeIns = Array(greetingCount).fill(null).map(() => {
        return useSpring(() => ({
            opacity: 0,
            config: { duration: 600 },
        }));
    });

    const [videoFadeInStyle, videoFadeInApi] = useSpring(() => ({
        opacity: 0,
        config: { duration: 600 },
    }));

    const [continueButtonFadeInStyle, continueButtonFadeInApi] = useSpring(() => ({
        opacity: 0,
        config: { duration: 600 },
    }));

    const getMediaUrl = () => {
        if (props.topic.greetingMessage.mediaUrl)
            return props.topic.greetingMessage.mediaUrl;
        if (props.topic.greetingMessage.videoUrl)
            return props.topic.greetingMessage.videoUrl;
        return null;
    };

    const mediaUrl = getMediaUrl();

    useEffect(() => {
        if (state.currentMessageFadeIn > -1) {
            if (messageFadeIns.length === 0) {
                activateGreetingTimer();
            } else {
                messageFadeIns[state.currentMessageFadeIn][1].start({
                    opacity: 1,
                    onRest: () => {
                        setState({ ...state, currentMessageText: state.currentMessageFadeIn });
                    }
                });
            }
        }
    }, [state.currentMessageFadeIn]);

    const continueButtonRef = useRef<HTMLButtonElement>(null);
    const continueButtonVisibleRef = useRef<boolean>(false);
    const [shouldPlay, setShouldPlay] = useState<boolean>(false);

    const activateGreetingTimer = () => {
        if (mediaUrl) {
            videoFadeInApi.start({
                opacity: 1,
                onRest: () => { setShouldPlay(true); }
            });
        } else {
            setTimeout(() => {
                fadeOut();
            }, props.topic.greetingMessage.duration * 1000);
        }
    };

    const fadeOut = () => {
        titlePanelApi.start({
            opacity: 0,
            onRest: props.onComplete
        });
    };

    const handleContinueButtonClicked = () => {
        if (continueButtonVisibleRef.current)
            fadeOut();
    };

    return (
        <animated.div style={titlePanelStyle}>
            {props.topic.greetingMessage.messages.map((message, i) => (
                <animated.div key={i} style={{ padding: '20px 20px 0 20px', ...messageFadeIns[i][0] }}>
                    <StyledContentPanel>
                        <TypingTextV2
                            active={state.currentMessageText === i}
                            delay={10}
                            onComplete={() => {
                                if (state.currentMessageFadeIn + 1 < greetingCount)
                                    setState({ ...state, currentMessageFadeIn: state.currentMessageFadeIn + 1 });
                                else
                                    activateGreetingTimer();
                            }}
                        >
                            <h1 key={i}>{message.text}</h1>

                        </TypingTextV2>
                    </StyledContentPanel>
                </animated.div>
            ))}
            {!mediaUrl ? null :
                <>
                    <animated.div style={{ padding: '20px', ...videoFadeInStyle }}>
                        <div style={{ borderRadius: '20px', overflow: 'hidden', }}>
                            <DisplayMedia
                                shouldPlay={shouldPlay}
                                mediaUrl={mediaUrl}
                                onAfterOneSecond={() => {
                                    continueButtonFadeInApi.start({
                                        opacity: 1,
                                        onRest: () => {
                                            if (continueButtonRef.current)
                                                continueButtonRef.current.style.cursor = 'pointer';
                                            continueButtonVisibleRef.current = true;
                                        }
                                    });
                                }}
                                onMediaEnded={() => {
                                    setTimeout(() => {
                                        fadeOut();
                                    }, props.topic.greetingMessage.duration * 1000);
                                }}
                            />
                        </div>
                    </animated.div>
                    <animated.div style={{ padding: '20px', ...continueButtonFadeInStyle }}>
                        <StyledButton ref={continueButtonRef} style={{ cursor: 'auto' }} onClick={handleContinueButtonClicked}>
                            Продължи
                        </StyledButton>
                    </animated.div>
                </>
            }
        </animated.div>
    );
};

export default Component;