import { faCakeCandles } from "@fortawesome/free-solid-svg-icons/faCakeCandles";
import { faMedal } from "@fortawesome/free-solid-svg-icons/faMedal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons/faThumbsUp";
import { faThumbsDown } from "@fortawesome/free-solid-svg-icons/faThumbsDown";
import { faMars } from "@fortawesome/free-solid-svg-icons/faMars";
import { faVenus } from "@fortawesome/free-solid-svg-icons/faVenus";
import { AppContext } from "../../../../App";
import { useContext } from "react";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons/faCircleQuestion";
import { faVenusMars } from "@fortawesome/free-solid-svg-icons/faVenusMars";
import { useTranslation } from "react-i18next";
import { fixBold } from "../../../../util/util";
import { GroupResult } from "@kazvabg/voterapp-model";

interface AgeGenderResultSlideProps {
    groupResult: GroupResult;
    voteValue: number;
}

const AgeGenderResultSlide: React.FC<AgeGenderResultSlideProps> = (props) => {
    const { profile } = useContext(AppContext);

    const { t } = useTranslation();

    if (!profile.gender || !profile.birthYear || !props.groupResult) {
        return (
            <>
                <span className='header-icon-row'>
                    <span className='icon'><FontAwesomeIcon icon={faVenusMars} /></span>
                    <span className='icon'><FontAwesomeIcon icon={faCakeCandles} /></span>
                    <span className='icon'><FontAwesomeIcon icon={faCircleQuestion} /></span>
                </span>
                <p style={{ fontSize: '16px' }}>
                    {t('results_age_gender_input_let_me_show_you')}
                </p>
            </>
        );
    }

    if (profile.gender === 'other')
        return <>{t('general_error_message')}</>;

    if (props.groupResult.voteCount === 1) {
        const genderIcon = profile.gender === 'male' ? faMars : faVenus;
        return (
            <>
                <span className='header-icon-row'>
                    <span className='icon'><FontAwesomeIcon icon={genderIcon} /></span>
                    <span className='icon'><FontAwesomeIcon icon={faCakeCandles} /></span>
                    <span className='icon'><FontAwesomeIcon icon={faMedal} /></span>
                </span>
                <p>
                    {profile.gender === 'male'
                        ? fixBold(t('results_age_gender_first_vote_male'))
                        : profile.gender === 'female'
                        ? fixBold(t('results_age_gender_first_vote_female'))
                        : t('general_error_message')
                    }
                </p>
            </>
        );
    }

    if (props.groupResult.voteHeight === null)
        return null;

    const genderIcon = profile.gender === 'male' ? faMars : faVenus;
    const genderStr = profile.gender === 'male' ? t('general_male_plural') : t('general_female_plural');

    const userVote = props.voteValue;
    const votesDiff = Math.abs(userVote - (props.groupResult.rating ?? 50));

    let agreementMessage;
    let agreementIcon;

    if(votesDiff <= 15) {
        agreementIcon = faThumbsUp;
        agreementMessage = fixBold(t('results_age_gender_strong_agreement', { genderStr }));
    } else if (votesDiff <= 30) {
        agreementIcon = faThumbsUp;
        agreementMessage = fixBold(t('results_age_gender_weak_agreement', { genderStr }));
    } else if(votesDiff <= 50) {
        agreementIcon = faThumbsDown;
        agreementMessage = fixBold(t('results_age_gender_weak_disagreement', { genderStr }));
    } else {
        agreementIcon = faThumbsDown;
        agreementMessage = fixBold(t('results_age_gender_strong_disagreement', { genderStr }));
    }

    return (
        <>
            <span className='header-icon-row'>
                <span className='icon'><FontAwesomeIcon icon={genderIcon} /></span>
                <span className='icon'><FontAwesomeIcon icon={faCakeCandles} /></span>
                <span className='icon'><FontAwesomeIcon icon={agreementIcon} /></span>
            </span>
            <p>{agreementMessage}</p>
        </>
    );
};

export default AgeGenderResultSlide;