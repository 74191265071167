import { faCakeCandles } from "@fortawesome/free-solid-svg-icons/faCakeCandles";
import { faThumbsDown } from "@fortawesome/free-solid-svg-icons/faThumbsDown";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons/faThumbsUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { AppContext } from "../../../../App";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons/faCircleQuestion";
import StyledDropDown from "../../../../components/StyledDropdown";
import { faMedal } from "@fortawesome/free-solid-svg-icons/faMedal";
import { useTranslation } from "react-i18next";
import { fixBold } from "../../../../util/util";
import { GroupResult } from "@kazvabg/voterapp-model";

interface AgeResultSlideProps {
    groupResult: GroupResult;
    voteValue: number;
    submitBirthYear: React.ChangeEventHandler;
}

const AgeResultSlide: React.FC<AgeResultSlideProps> = (props) => {
    const { profile } = useContext(AppContext);

    const { t } = useTranslation();

    if (!profile.birthYear || !props.groupResult)
        return (
            <>
                <span className='header-icon-row'>
                    <span className='icon'><FontAwesomeIcon icon={faCakeCandles} /></span>
                    <span className='icon'><FontAwesomeIcon icon={faCircleQuestion} /></span>
                </span>
                <p style={{ fontSize: '16px', marginBottom: '10px' }}>{t('results_age_input_let_me_show_you')}</p>
                <StyledDropDown style={{ fontSize: '16px', padding: '17px 20px', marginLeft: '-6px', width: 'calc(100% + 12px)' }} onChange={props.submitBirthYear} value={t('input_placeholder_birth_year')}>
                    <option disabled>{t('input_placeholder_birth_year')}</option>
                    {Array.from(Array(77).keys()).map(n => 2016 - n).map(year =>
                        <option key={year} value={year}>{year}</option>
                    )}
                </StyledDropDown>
                <p style={{ fontSize: '11px', marginTop: '10px' }}>{t('results_age_input_disclaimer')}</p>
            </>
        );

    //const ageGroup: AgeGroup | null = getAgeGroup(profile.birthYear);

    //if (ageGroup === null)
    //    return null;

    if (props.groupResult.voteCount === 1) {
        return (
            <>
                <span className='header-icon-row'>
                    <span className='icon'><FontAwesomeIcon icon={faCakeCandles} /></span>
                    <span className='icon'><FontAwesomeIcon icon={faMedal} /></span>
                </span>
                <p>
                    {fixBold(t('results_age_first_vote'))}
                </p>
            </>
        );
    }

    if (props.groupResult.rating === null)
        return null;

    const averageVote: number = props.groupResult.rating;
    const userVote = props.voteValue;

    const votesDiff = Math.abs(userVote - averageVote);

    let agreementMessage;
    let agreementIcon;

    if(votesDiff <= 15) {
        agreementIcon = faThumbsUp;
        agreementMessage = fixBold(t('results_age_strong_agreement'));
    } else if (votesDiff <= 30) {
        agreementIcon = faThumbsUp;
        agreementMessage = fixBold(t('results_age_weak_agreement'));
    } else if(votesDiff <= 50) {
        agreementIcon = faThumbsDown;
        agreementMessage = fixBold(t('results_age_weak_disagreement'));
    } else {
        agreementIcon = faThumbsDown;
        agreementMessage = fixBold(t('results_age_strong_disagreement'));
    }

    return (
        <>
            <span className='header-icon-row'>
                <span className='icon'><FontAwesomeIcon icon={faCakeCandles} /></span>
                <span className='icon'><FontAwesomeIcon icon={agreementIcon} /></span>
            </span>
            <p>{agreementMessage}</p>
        </>
    );
};

export default AgeResultSlide;