import axios from 'axios';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Home from './pages/Home';
import TopicComponent from './pages/Topic';

import styled, { createGlobalStyle } from 'styled-components';
import { TopicV2 } from '@kazvabg/voterapp-model';
import { getCookie, setCookie } from './util/cookies';

import RotateMe from './components/RotateMe';
import PolicyScreen from './menu/PolicyScreen';
import ConsentScreen from './menu/ConsentScreen';
import GlassPanel from './components/GlassPanel';
import MainMenu from './menu/MainMenu';
import ProfileComponent from './menu/Profile';
import LanguageScreen from './menu/LanguageScreen';

import { Toaster } from 'react-hot-toast';
import DeleteProfileAYSScreen from './menu/profile/DeleteProfileAYSScreen';

import './i18n';
import LoadingTopicScreen from './components/LoadingTopicScreen';

const GlobalStyle = createGlobalStyle`

    body, input, button {
        margin: 0;
        //font-family: Raleway, sans-serif;
        font-family: sans-serif;
    }

    select, option {
        //font-family: Raleway, sans-serif;
    }

    h3 {
        font-size: 28px;
        font-weight: bold;
    }
    
    p {
        line-height: 1.7;
        font-size: 22px;
        font-weight: 300;
        color: #333;
        b {
            font-weight: 999;
        }
    }
    a:active, a:focus {
        outline: 0;
        border: none;
        -moz-outline-style: none;
    }
`;

const BackgroundContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: -1;
    transition: filter 0.6s ease;
    background-color: black;
`;

const BackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    display: none;
    z-index: -2;
    z-index: -2;
    display: block;

    @media (orientation: portrait) {
        display: none;
    }
`;

const BackgroundImagePortrait = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    display: none;
    z-index: -2;

    @media (orientation: portrait) {
        display: block;
    }
`;

const AppContainer = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
`;

export type GenderData = 'male' | 'female' | 'other' | null;

export enum MenuScreen {
    None = 'none',
    MainMenu = 'main-menu',
    Language = 'language',
    Profile = 'profile',
    PrivacyNotice = 'privacy-notice',
    PrivacyNoticeMenu = 'privacy-notice-menu',
    Consent = 'consent',
    DeleteProfileAYS = 'delete-profile-ays'
}

export interface ProfileData {
    userId: string;
    locale: string;
    name: string;
    gender: GenderData;
    birthYear: number | null;
    cityName: string;
    regionName: string;
    countryName: string;
}

const emptyProfile: ProfileData = {
    userId: '',
    locale: '',
    name: '',
    gender: null,
    birthYear: null,
    cityName: '',
    regionName: '',
    countryName: ''
};

interface AppContextType {
    apiUrl: string;
    profile: ProfileData;
    setProfile: (profile: ProfileData | null) => void;
    topic: TopicV2 | null;
    showResults: boolean;
    setTopic: (topic: TopicV2 | null) => void;
    cookieConsent: true | false | null;
    giveCookieConsent: () => void;
    menuScreen: MenuScreen;
    setMenuScreen: (menuScreen: MenuScreen) => void;
    setGlassPanelColor: (color: string) => void;
    setGlassPanelBackdropFilter: (blur: number, saturate: number, brightness: number) => void;
    setLoading: (loading: boolean) => void;
}

export const AppContext = React.createContext<AppContextType>({
    apiUrl: '',
    profile: emptyProfile,
    setProfile: () => { throw new Error("Function not implemented"); },
    topic: null,
    showResults: false,
    setTopic: () => { throw new Error("Function not implemented"); },
    cookieConsent: null,
    giveCookieConsent: () => { throw new Error("Function not implemented"); },
    menuScreen: MenuScreen.None,
    setMenuScreen: () => { throw new Error("Function not implemented"); },
    setGlassPanelColor: () => { throw new Error("Function not implemented") },
    setGlassPanelBackdropFilter: () => { throw new Error("Function not implemented") },
    setLoading: () => { throw new Error("Function not implemented") },
});

interface AppState {
    loading: boolean;
    profile: ProfileData | null;
    topic: TopicV2 | null;
    showResults: boolean;
    cookieConsent: boolean;
    menuScreen: MenuScreen;
}

export default () => {
    const apiUrl = process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_STAGING
            ? 'https://staging-api.kazva.bg/api/v1'
            : 'https://prod.kazva.bg/api/v1'
        : 'http://localhost:8000/api/v1';
    //: 'http://192.168.1.10:8000/api/v1';

    const glassPanelRef = useRef<HTMLDivElement>(null);

    const [state, setState] = useState<AppState>({
        loading: true,
        profile: null,
        topic: null,
        showResults: false,
        cookieConsent: getCookie('cookieConsent') === "true",
        menuScreen: getCookie('cookieConsent') === "true" ? MenuScreen.None : MenuScreen.Consent,
    });
    const stateRef = useRef<AppState>(state);

    const setTopic = (newTopic: TopicV2 | null) => {

        const showResults: boolean | undefined =
            newTopic?.pvt.showPeopleStats ||
            newTopic?.pvt.showGenderStats ||
            newTopic?.pvt.showAgeStats ||
            newTopic?.pvt.showGenderByAgeStats;

        stateRef.current = { ...stateRef.current, topic: newTopic, showResults: !!showResults };
        setState(stateRef.current);
    };

    useEffect(() => {
        if (!state.profile) {
            axios.get(`${apiUrl}/user`).then(res => {
                stateRef.current = { ...stateRef.current, profile: res.data.profile };
                setState(stateRef.current);
            });
        }
    }, [state.profile]);

    const giveCookieConsent = () => {
        setCookie('cookieConsent', 'true', 365);
        stateRef.current = { ...stateRef.current, cookieConsent: true, menuScreen: MenuScreen.None };
        setState(stateRef.current);
    };

    const setGlassPanelColor = (color: string) => {
        if (glassPanelRef.current) {
            glassPanelRef.current.style.backgroundColor = color;
        }
    };

    const setGlassPanelBackdropFilter = (blur: number, saturate: number, brightness: number) => {
        if (glassPanelRef.current) {
            const styleObject = glassPanelRef.current.style as any;
            styleObject.backdropFilter = `blur(${blur}px) saturate(${saturate}) brightness(${brightness})`;
            styleObject['-webkit-backdrop-filter'] = `blur(${blur}px) saturate(${saturate}) brightness(${brightness})`;
        }
    };

    const renderMenuScreen = () => {
        switch (state.menuScreen) {
            case MenuScreen.None:
                return null;
            case MenuScreen.Consent:
                return <ConsentScreen />;
            case MenuScreen.PrivacyNotice:
                return <PolicyScreen prevScreen={MenuScreen.Consent} />;
            case MenuScreen.PrivacyNoticeMenu:
                return <PolicyScreen prevScreen={MenuScreen.MainMenu} />;
            case MenuScreen.MainMenu:
                return <MainMenu />;
            case MenuScreen.Profile:
                return <ProfileComponent />;
            case MenuScreen.Language:
                return <LanguageScreen />
            case MenuScreen.DeleteProfileAYS:
                return <DeleteProfileAYSScreen />

            default: return null;
        }
    };

    const setProfile = (profile: ProfileData | null) => {
        stateRef.current = { ...stateRef.current, profile };
        setState(stateRef.current);
    };

    const setLoading = (loading: boolean) => {
        stateRef.current = { ...stateRef.current, loading };
        setState(stateRef.current);
    };

    const setMenuScreen = (menuScreen: MenuScreen) => {
        stateRef.current = { ...stateRef.current, menuScreen };
        setState(stateRef.current);
    };

    const getBackgroundImage = () => {
        if (!state.topic)
            return '';

        if (state.topic.backgroundUrl)
            return state.topic.backgroundUrl;

        return state.topic.image;
    };

    const getBackgroundImagePortrait = () => {
        if (!state.topic)
            return '';

        if (state.topic.backgroundPortraitUrl)
            return state.topic.backgroundPortraitUrl;

        if (state.topic.backgroundUrl)
            return state.topic.backgroundUrl;

        return state.topic.image;
    };

    return (
        !state.profile ? null : //<h5>Зареждане на профил...</h5> :
            <AppContext.Provider value={{
                apiUrl,
                profile: state.profile, setProfile,
                topic: state.topic, setTopic,
                showResults: state.showResults,
                cookieConsent: state.cookieConsent, giveCookieConsent,
                menuScreen: state.menuScreen, setMenuScreen,
                setGlassPanelColor, setGlassPanelBackdropFilter,
                setLoading
            }}>
                <GlobalStyle />
                <RotateMe />
                <Toaster />
                <AppContainer>
                    {/*<BackgroundImage src={state.topic?.ti ? state.topic.ti : '/images/gradient-backgrounds/GradientTextures-04.jpg'} />*/}
                    <GlassPanel style={{ backgroundColor: '#000' }} ref={glassPanelRef}>
                        {renderMenuScreen()}
                        <div style={{
                            width: '100%',
                            height: '100%',
                            position: 'relative',
                            display: state.menuScreen === MenuScreen.None ? 'block' : 'none'
                        }}>
                            <BrowserRouter>
                                <Switch>
                                    <Route path='/:topicHash' component={TopicComponent} />
                                    <Route exact path='/' component={Home} />
                                </Switch>
                            </BrowserRouter>
                        </div>
                    </GlassPanel>
                    <LoadingTopicScreen isLoading={state.loading} />
                    <BackgroundContainer>
                        <BackgroundImage onLoad={() => { if(stateRef.current.loading) setLoading(false) }} src={getBackgroundImage()} />
                        <BackgroundImagePortrait onLoad={() => { if(stateRef.current.loading) setLoading(false) }} src={getBackgroundImagePortrait()} />
                    </BackgroundContainer>
                </AppContainer>
            </AppContext.Provider >
    );
};
